import { useCallback, useEffect, useMemo, useState } from "react";
import { ReloadIcon, cn } from "@androshq/shared-ui";
import { GridApi, GridReadyEvent, RowClickedEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { PageActionBar, PageActionBarData } from "../../../components/navigation/PageActionBar";
import { PageContent } from "../../../components/navigation/PageContent";
import { STD_GRID_OPTIONS } from "../../../components/tables/standards";
import { useItemsView } from "./hooks/useItemsView";

export function ItemsPage() {
  const navigate = useNavigate();
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [tableStatus, setTableStatus] = useState<"loading" | "empty" | "loaded">("loading");

  const { data, isLoading, refetch, defaultColDef, getColumnDefs, selectedCompanyIds, getRowHref } = useItemsView();
  const columnDefs = useMemo(() => getColumnDefs(), [getColumnDefs]);

  const pageActionBarData: PageActionBarData = {
    filters: {
      pickers: [{}],
      switches: [
        {
          data: [
            {
              label: "Forecast Recommendations",
              href: "/sop/items?view=forecast_recommendations",
            },
            {
              label: "Inventory Balances",
              href: "/sop/items?view=inventory_rollforward",
            },
          ],
        },
      ],
    },
    actions: [
      {
        title: "Refresh",
        isLoading: isLoading,
        onClick: refetch,
        disabledData: data,
        icon: <ReloadIcon className={cn("mr-2", { "animate-spin": isLoading })} />,
      },
    ],
  };

  const noRowsText = useMemo(() => {
    if (selectedCompanyIds.length === 0) {
      return "You must first select at least one customer";
    }
    return "No results available for the selected customers";
  }, [selectedCompanyIds.length]);

  const getRowId = useCallback((params: any) => params.data.item.id, []);
  const onGridReady = useCallback((event: GridReadyEvent) => setGridApi(event.api), []);

  useEffect(() => {
    if (!gridApi) return;

    if (isLoading) {
      setTableStatus("loading");
    } else if (data?.length === 0 || selectedCompanyIds.length === 0) {
      setTableStatus("empty");
      gridApi.updateGridOptions({
        rowData: [],
        columnDefs,
        ...STD_GRID_OPTIONS,
      });
    } else if (data?.length! > 0) {
      setTableStatus("loaded");
      gridApi.updateGridOptions({
        rowData: data,
        columnDefs,
        ...STD_GRID_OPTIONS,
      });
    }
  }, [gridApi, data, isLoading, columnDefs, selectedCompanyIds]);

  useEffect(() => {
    if (!gridApi) return;

    switch (tableStatus) {
      case "loading":
        gridApi.showLoadingOverlay();
        break;
      case "empty":
        gridApi.showNoRowsOverlay();
        break;
      case "loaded":
        gridApi.hideOverlay();
        break;
    }
  }, [gridApi, tableStatus]);

  const handleRowClick = useCallback(
    (event: RowClickedEvent) => {
      const rowHref = getRowHref(event.data.item.id);
      const mouseEvent = event.event as MouseEvent;
      if (mouseEvent.metaKey) {
        window.open(rowHref, "_blank");
      } else {
        navigate(rowHref);
      }
    },
    [navigate, getRowHref],
  );
  return (
    <>
      <PageActionBar
        className="flex items-center justify-between flex-wrap gap-3"
        pageActionBarData={pageActionBarData}
      />
      <PageContent className="flex-col items-start gap-y-6 pt-0">
        <div className="ag-theme-quartz h-full w-full">
          <AgGridReact
            reactiveCustomComponents
            suppressCellFocus
            suppressLoadingOverlay={selectedCompanyIds.length === 0}
            loadingOverlayComponent={() => <ReloadIcon className="h-6 w-6 animate-spin" />}
            noRowsOverlayComponent={() => <div>{noRowsText}</div>}
            rowBuffer={26}
            rowHeight={64}
            onRowClicked={handleRowClick}
            {...{ defaultColDef, onGridReady, getRowId }}
          />
        </div>
      </PageContent>
    </>
  );
}
