import { ColDef } from "ag-grid-community";
import { State } from "../../../../../api/types";
import { ReasonVendorSelectionCT, ReasonVendorSelectionPCL } from "../../../../../api/types";
import { formatCellDate } from "../../../../../utils";

const getReasonPCL = (key: keyof typeof ReasonVendorSelectionPCL) => {
  let reason_pcl = "";
  if (key) {
    reason_pcl = ReasonVendorSelectionPCL[key];
  }

  return reason_pcl;
};

const getReasonCT = (key: keyof typeof ReasonVendorSelectionCT) => {
  let reason_ct = "";
  if (key) {
    reason_ct = ReasonVendorSelectionCT[key];
  }
  return reason_ct;
};

export const defaultColDef: ColDef = {
  flex: 1,
  resizable: true,
  suppressHeaderMenuButton: true,
  suppressMovable: true,
  sortable: false
};

export const getColumnDefs = (locations: State[], vendors: State[]): ColDef[] => [
  { field: "source_row_name", headerName: "Record", flex: 1, minWidth: 140 },
  {
    field: "to_vendor",
    headerName: "To Vendor",
    valueFormatter: formatCellDate,
    valueGetter: (params) => {
      return params.data.state_transitions[0].at
    },
  },
  {
    field: "toShipment",
    headerName: "To Shipment",
    valueFormatter: formatCellDate,
    valueGetter: (params) => params.data.state_transitions[1].at,
  },
  {
    field: "toLocation",
    headerName: "To Location",
    valueFormatter: formatCellDate,
    valueGetter: (params) => params.data.state_transitions[2].at,
  },
  { field: "quantity", headerName: "Quantity", maxWidth: 90 },
  {
    field: "vendor",
    headerName: "Vendor",
    valueGetter: (params) => params.data.state_transitions[0].to_state_id,
    refData: vendors?.reduce(
      (acc, vendor) => {
        acc[vendor.id] = vendor.name;
        return acc;
      },
      {} as Record<string, string>,
    ),
  },
  { field: "shipping_method", headerName: "Shp Method", minWidth: 100 },
  {
    field: "location",
    headerName: "Location",
    valueGetter: (params) => params.data.state_transitions[2].to_state_id,
    refData: locations?.reduce(
      (acc, location) => {
        acc[location.id] = location.name;
        return acc;
      },
      {} as Record<string, string>,
    ),
  },
  {
    field: "reason_ct",
    headerName: "Reason CT",
    valueGetter: (params) => getReasonCT(params.data.source_metadata?.reason_vendor_selection_ct),
  },
  {
    field: "reason_pcl",
    headerName: "Reason PCL",
    valueGetter: (params) => getReasonPCL(params.data.source_metadata?.reason_vendor_selection_pcl),
  },
];
