import { useCallback, useEffect, useMemo, useState } from "react";
import { useAuth } from "@androshq/auth/browser-client";
import { Department, checkUserForPermissions } from "@androshq/auth/common";
import { Permission } from "@androshq/auth/common";
import {
  Button,
  Card,
  CardContent,
  Cross2Icon,
  DialogHeader,
  PlusIcon,
  ReloadIcon,
  buttonVariants,
} from "@androshq/shared-ui";
import {
  CellValueChangedEvent,
  ColDef,
  GetRowIdParams,
  GridApi,
  GridReadyEvent,
  ICellRendererParams,
  NewValueParams,
  RowDataUpdatedEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { subDays } from "date-fns";
import _ from "lodash";
import { sopApiClient } from "../../../../../api";
import { calculateRowDates } from "../../../../../api/standards";
import {
  DAYS_CARGO_READY_TO_SHIP,
  ItemWithConfig,
  ReasonVendorSelectionCT,
  ReasonVendorSelectionPCL,
  ShippingMethod,
  ShippingMethodEnum,
  getShippingMethodEnumByLabel,
} from "../../../../../api/types";
import Tooltip from "../../../../../components/messages/Tooltip";
import { defaultGridRichSelectParams } from "../../../../../components/tables/standards";
import { useStateTransition } from "../../../../../hooks/supply-planning/useStateTransitionsHelper";
import { useCachedData } from "../../../../../hooks/use-cached-data";
import { formatCellDate, generateId, getISODateString } from "../../../../../utils";
import { WorkflowName } from "../../../types";
import { WorkflowVersion } from "../../../types";
import {
  CAWorkflowEventRequestData,
  CAWorkflowExecution,
  EventTypeEnum,
  Row,
  RowGroup,
  StateTransition,
} from "../../types";
import WorkflowModalStatusBar from "./WorkflowModalStatusBar";
import { checkIsDateChanged } from "../../../../../utils";  

interface Props {
  data: CAWorkflowExecution | null;
  invalidateData: () => void;
  closeModal: () => void;
}

interface RowDataCurrentVersion {
  id: string;
  record: string | undefined;
  toVendor: Date | undefined;
  toShipment: Date | undefined;
  toLocation: Date | undefined;
  totalQuantity: number | undefined;
  vendor: string | undefined;
  shippingMethod: string | undefined;
  sourceRowId?: string | null;
  createdRowId?: string | null;
  location: string | undefined;
  stateTransitions: StateTransition[] | undefined;
  reason_vendor_selection_ct?: string;
  removeRow: boolean;
}

const RemoveRowCell = ({ value, node, api }: ICellRendererParams) => {
  const handleRemove = () => api.applyTransaction({ remove: [node.data] });

  if (!value) return null;
  return (
    <div className="flex items-center justify-center gap-x-1 w-full h-full">
      <button
        className="grid place-items-center h-[80%] aspect-square bg-red-50 hover:bg-red-100 rounded-sm transition"
        onClick={handleRemove}
      >
        <Cross2Icon className="w-4 h-4 text-destructive" />
      </button>
    </div>
  );
};

export default function CAModalSectionInputs({ data, invalidateData, closeModal }: Props) {
  const [memo, setMemo] = useState<string>("");
  const [loadingAction, setLoadingAction] = useState<"edit" | "approve" | "reject" | "cancel" | null>(null);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  // const [initialRowData, setInitialRowData] = useState<RowDataCurrentVersion[] | undefined>();
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const { user } = useAuth();

  const { companies } = useCachedData();
  const { getStateById, items, locations, vendors, isLoading } = useStateTransition();

  const itemId = data?.state.version_history?.[0].data.item_id;
  const companyId = data?.state.version_history?.[0].data.company_id;
  const sourceRowName = data?.state.version_history?.[0].data.source_row_name;
  let itemWithConfig: ItemWithConfig | undefined;
  if (itemId) {
    itemWithConfig = _.find(items, { id: itemId });
  }

  const vendorOptions = useMemo(() => {
    const item = _.find(items, { id: itemId });
    return _.keys(item?.config?.vendor).map((vendorId) => _.find(vendors, { id: +vendorId })?.name);
  }, [items, vendors, itemId]);

  const locationOptions = useMemo(() => {
    const item = _.find(items, { id: itemId });
    const locationIds = _.keys(item?.config?.location);
    return _.filter(locations, (location) => locationIds.includes(location.id.toString()))?.map(
      (location) => location.name,
    );
  }, [items, locations, itemId]);

  const onGridReady = useCallback((event: GridReadyEvent) => setGridApi(event.api), []);
  const getRowId = useCallback((params: GetRowIdParams) => params.data.id, []);

  const getDateCellColumnDefs = () => ({
    cellEditor: "agDateCellEditor",
    valueFormatter: formatCellDate,
  });

  const rowData: RowDataCurrentVersion[] | undefined = useMemo(
    () =>
      data?.state?.version_history[0].data.rows.map((row) => {
        const vendorTransition = _.find(row.state_transitions, { state_type: "vendor" });
        const shipmentTransition = _.find(row.state_transitions, { state_type: "shipment" });
        const locationTransition = _.find(row.state_transitions, { state_type: "location" });

        if (vendorTransition === undefined) throw new Error("Can`t find vendorTransition");
        if (shipmentTransition === undefined) throw new Error("Can`t find shipmentTransition");
        if (locationTransition === undefined) throw new Error("Can`t find locationTransition");

        const vendorDate = new Date(vendorTransition.at);
        const shipmentDate = new Date(shipmentTransition.at);
        const locationDate = new Date(locationTransition.at);

        let reason_ct = "";
        let reason_pcl = "";
        if (row?.source_metadata?.reason_vendor_selection_ct) {
          reason_ct = ReasonVendorSelectionCT[row?.source_metadata?.reason_vendor_selection_ct];
        }
        if (row?.source_metadata?.reason_vendor_selection_pcl) {
          reason_pcl = ReasonVendorSelectionPCL[row?.source_metadata?.reason_vendor_selection_pcl];
        }

        return {
          id: generateId(),
          record: data.state?.version_history[0].data.source_row_name,
          toVendor: vendorDate,
          cargoReady: subDays(shipmentDate, DAYS_CARGO_READY_TO_SHIP),
          toShipment: shipmentDate,
          toLocation: locationDate,
          totalQuantity: row.quantity,
          sourceRowId: row.source_row_id_hash,
          createdRowId: row.created_row_id_hash,
          vendor: getStateById(vendorTransition?.to_state_id)?.name,
          shippingMethod: ShippingMethod[row.shipping_method],
          location: getStateById(locationTransition?.to_state_id)?.name,
          reason_vendor_selection_ct: reason_ct,
          reason_vendor_selection_pcl: reason_pcl,
          stateTransitions: row.state_transitions,
          source_metadata: row?.source_metadata,
          removeRow: false,
        };
      }),
    [data, getStateById],
  );

  const updateRowDatesOnConfigChanged = (params: NewValueParams, dateInputType: "atVendor" | "cargoReady" | "atShipment" | "atLocation" = "atLocation") => {

    const vendorObject = _.find(vendors, { name: params.data.vendor });
    if (vendorObject === undefined) {
      throw new Error("Can`t find vendor");
    }
    if (itemWithConfig === undefined) {
      throw new Error("Can`t find item");
    }
    let anchorDate = params.data.toLocation;
    if (dateInputType === "atVendor") {
      anchorDate = params.data.toVendor;
    } else if (dateInputType === "atShipment") {
      anchorDate = params.data.toShipment;
    } else if (dateInputType === "cargoReady") {
      anchorDate = params.data.cargoReady;
    }

    const { atVendor, atShipment, atLocation } = calculateRowDates(
      itemWithConfig,
      vendorObject.id,
      params.data.shippingMethod,
      dateInputType,
      anchorDate,
    );
    console.log(params.newValue,dateInputType, atVendor, atShipment, atLocation);

    params.api.applyTransactionAsync({
      update: [
        {
          ...params.data,
          toVendor: atVendor,
          cargoReady: subDays(atShipment, DAYS_CARGO_READY_TO_SHIP),
          toShipment: atShipment,
          toLocation: atLocation,
        },
      ],
    });
  };

  const columnDefs = useMemo<ColDef[]>(
    () => [
      { field: "record", headerName: "Record", flex: 1, minWidth: 140, resizable: true },
      {
        field: "toVendor",
        headerName: "To Vendor",
        ...getDateCellColumnDefs(),
        resizable: true,
      },
      {
        field: "cargoReady",
        headerName: "Cargo Ready",
        editable: true,
        cellClass: "cursor-text",
        singleClickEdit: true,
        resizable: true,
        ...getDateCellColumnDefs(),
        onCellValueChanged: (params) => {
          updateRowDatesOnConfigChanged(params, "cargoReady");
        },
      },
      {
        field: "toShipment",
        headerName: "To Shipment",
        editable: true,
        resizable: true,
        cellClass: "cursor-text",
        singleClickEdit: true,
        ...getDateCellColumnDefs(),
        onCellValueChanged: (params) => {
          updateRowDatesOnConfigChanged(params, "atShipment");
        },
      },
      {
        field: "toLocation",
        headerName: "To Location",
        editable: true,
        resizable: true,
        cellClass: "cursor-text",
        singleClickEdit: true,
        ...getDateCellColumnDefs(),
        onCellValueChanged: (params) => {
          updateRowDatesOnConfigChanged(params, "atLocation");
        },
      },
      {
        field: "totalQuantity",
        headerName: "Quantity",
        maxWidth: 90,
        editable: true,
        resizable: true,
        cellClass: "cursor-text",
        singleClickEdit: true,
      },
      {
        field: "vendor",
        headerName: "Vendor",
        editable: true,
        cellClass: "cursor-text",
        resizable: true,
        singleClickEdit: true,
        cellEditorSelector: () => {
          return {
            component: "agRichSelectCellEditor",
            params: {
              ...defaultGridRichSelectParams,
              values: vendorOptions,
            },
          };
        },
        onCellValueChanged: (params) => {
          updateRowDatesOnConfigChanged(params, "atLocation");
        },
      },
      {
        field: "shippingMethod",
        headerName: "Shp Method",
        editable: true,
        cellClass: "cursor-text",
        singleClickEdit: true,
        resizable: true,
        minWidth: 100,
        cellEditorSelector: () => {
          const values = [ShippingMethodEnum.Ocean];

          if (itemWithConfig) {
            if (itemWithConfig.config.company.shipment_time_days_air) {
              values.push(ShippingMethodEnum.Air);
            }
            if (itemWithConfig.config.company.shipment_time_days_fastvessel) {
              values.push(ShippingMethodEnum.FastVessel);
            }
          }
          return {
            component: "agRichSelectCellEditor",
            params: {
              ...defaultGridRichSelectParams,
              values,
            },
          };
        },
        onCellValueChanged: (params) => {
          updateRowDatesOnConfigChanged(params, "atLocation");
        },
      },
      {
        field: "location",
        headerName: "Location",
        editable: true,
        cellClass: "cursor-text",
        singleClickEdit: true,
        resizable: true,
        cellEditorSelector: () => ({
          component: "agRichSelectCellEditor",
          params: {
            ...defaultGridRichSelectParams,
            values: locationOptions,
          },
        }),
      },
      {
        field: "reason_vendor_selection_ct",
        headerName: "Reason CT",
        editable: true,
        cellClass: "cursor-text",
        singleClickEdit: true,
        cellEditorSelector: () => ({
          component: "agRichSelectCellEditor",
          params: {
            allowTyping: true,
            valueListMaxWidth: 140,
            valueListMaxHeight: 100,
            filterList: true,
            highlightMatch: true,
            values: _.values(ReasonVendorSelectionCT),
          },
        }),
      },
      {
        field: "reason_vendor_selection_pcl",
        headerName: "Reason PCL",
        editable: true,
        cellClass: "cursor-text",
        singleClickEdit: true,
        cellEditorSelector: () => ({
          component: "agRichSelectCellEditor",
          params: {
            allowTyping: true,
            valueListMaxWidth: 140,
            valueListMaxHeight: 100,
            filterList: true,
            highlightMatch: true,
            values: _.values(ReasonVendorSelectionPCL),
          },
        }),
      },

      { field: "removeRow", headerName: "", cellRenderer: "removeRowCell", maxWidth: 30 },
    ],
    [data, items, locations, vendors],
  );

  const createNewRowData = (): RowDataCurrentVersion => {
    if (!gridApi) throw new Error("Can`t find gridApi");
    const lastRowData = gridApi?.getDisplayedRowAtIndex(0)?.data as RowDataCurrentVersion;
    const newRow = _.cloneDeep(lastRowData);

    return {
      ...newRow,
      id: generateId(),
      stateTransitions: newRow.stateTransitions?.map((st) => {
        delete st["id"];
        return st;
      }),
      createdRowId: null,
      removeRow: true,
    };
  };

  const addNewRow = () => {
    if (!gridApi) return;

    const newRow = createNewRowData();
    gridApi.applyTransaction({ add: [newRow] });
  };

  const defaultColDef: ColDef = {
    resizable: false,
    flex: 1,
    suppressHeaderMenuButton: true,
    suppressMovable: true,
  };

  const generateMainPayloadPart = (): Omit<CAWorkflowEventRequestData, "workflow_event_type"> => ({
    workflow_type_name: WorkflowName.ChangeApprovalsWorkflow,
    workflow_type_version: WorkflowVersion.v1_0,
    workflow_id: data?.workflow_id,
    workflow_run_id: data?.workflow_run_id,
    memo,
  });

  const handleModalCloseWithCallback = async (callbackFn: () => Promise<void>) => {
    await callbackFn();
    closeModal();
  };

  const handleApproveWithEdit = async () => {
    setLoadingAction("edit");

    const rows: Row[] = [];

    gridApi?.forEachNode((node) => {
      const data = node.data;

      const vendor = _.find(data.stateTransitions, { state_type: "vendor" });
      const shipment = _.find(data.stateTransitions, { state_type: "shipment" });
      const location = _.find(data.stateTransitions, { state_type: "location" });

      const newVendorId = _.find(vendors, { name: data.vendor })?.id;
      const newLocationId = _.find(locations, { name: data.location })?.id;

      const stateTransitions: StateTransition[] = [
        {
          ...vendor,
          to_state_id: newVendorId,
          at: getISODateString(data.toVendor),
        },
        {
          ...shipment,
          at: getISODateString(data.toShipment),
        },
        {
          ...location,
          to_state_id: newLocationId,
          at: getISODateString(data.toLocation),
        },
      ];
      const reason_ct = _.findKey(ReasonVendorSelectionCT, (reason) => reason === data.reason_vendor_selection_ct);
      const reason_pcl = _.findKey(ReasonVendorSelectionPCL, (reason) => reason === data.reason_vendor_selection_pcl);
      const reasons: { reason_vendor_selection_pcl?: string; reason_vendor_selection_ct?: string } = {};
      if (reason_ct !== null && reason_ct !== undefined && reason_ct !== "0") {
        reasons.reason_vendor_selection_ct = reason_ct;
      }

      if (reason_pcl !== null && reason_pcl !== undefined && reason_pcl !== "0") {
        reasons.reason_vendor_selection_pcl = reason_pcl;
      }

      const keysToExclude = ["reason_vendor_selection_pcl", "reason_vendor_selection_ct"];

      const source_metadata = {
        ...Object.keys(data?.source_metadata || {}).reduce(
          (acc, key) => {
            if (!keysToExclude.includes(key)) {
              acc[key] = data?.source_metadata[key];
            }
            return acc;
          },
          {} as typeof data.source_metadata,
        ),
        ...reasons,
      };

      rows.push({
        quantity: data.totalQuantity,
        shipping_method: getShippingMethodEnumByLabel(data.shippingMethod),
        source_row_id_hash: data.sourceRowId,
        created_row_id_hash: data.createdRowId,
        state_transitions: stateTransitions,
        source_metadata,
      });
    });

    try {
      const rowGroup: RowGroup = {
        company_id: companyId!,
        item_id: itemId!,
        source_row_name: sourceRowName!,
        rows,
      };

      const payload: CAWorkflowEventRequestData = {
        ...generateMainPayloadPart(),
        workflow_event_type: EventTypeEnum.EDIT,
        data: rowGroup,
      };

      const response = await sopApiClient.workflows.executions.signal(payload);
      if (response.success) invalidateData();
    } finally {
      setLoadingAction(null);
    }
  };

  const handleApprove = async () => {
    setLoadingAction("approve");

    try {
      const payload: CAWorkflowEventRequestData = {
        ...generateMainPayloadPart(),
        workflow_event_type: EventTypeEnum.APPROVE,
      };

      const response = await sopApiClient.workflows.executions.signal(payload);
      if (response.success) invalidateData();
    } finally {
      setLoadingAction(null);
    }
  };

  const handleCancel = async () => {
    setLoadingAction("cancel");

    try {
      const payload: CAWorkflowEventRequestData = {
        ...generateMainPayloadPart(),
        workflow_event_type: EventTypeEnum.CANCEL,
      };

      const response = await sopApiClient.workflows.executions.signal(payload);
      if (response.success) invalidateData();
    } finally {
      setLoadingAction(null);
    }
  };

  const handleCheckSetDirty = (params: CellValueChangedEvent | RowDataUpdatedEvent) => {
    let rowCount = 0;
    const initialRowCurrent = params.api.getDisplayedRowAtIndex(0)?.data;
    const initialRow = initialRowData?.[0];
    params.api.forEachNode(() => {
      rowCount += 1;
    });
    if (!initialRow) {
      throw new Error("Initial row data is undefined");
    }
    if (!initialRowCurrent) {
      throw new Error("Initial row current data is undefined");
    }
    if (rowCount > 1) {
      setIsDirty(true);
    } else {
      if (initialRow.shippingMethod != initialRowCurrent.shippingMethod) setIsDirty(true);
      else if (initialRow.vendor != initialRowCurrent.vendor) setIsDirty(true);
      else if (initialRow.location != initialRowCurrent.location) setIsDirty(true);
      else if (initialRow.totalQuantity != initialRowCurrent.totalQuantity) setIsDirty(true);
      else if (checkIsDateChanged(initialRow.toVendor, initialRowCurrent.toVendor)) setIsDirty(true);
      else if (checkIsDateChanged(initialRow.toShipment, initialRowCurrent.toShipment)) setIsDirty(true);
      else if (checkIsDateChanged(initialRow.toLocation, initialRowCurrent.toLocation)) setIsDirty(true);
      else setIsDirty(false);
    }
  };

  const initialRowData = useMemo(() => {
    return _.cloneDeep(rowData);
  }, [rowData]);

  useEffect(() => {
    if (!gridApi) return;

    if (isLoading) {
      gridApi.showLoadingOverlay();
    } else if (!rowData) {
      gridApi.showNoRowsOverlay();
      gridApi.updateGridOptions({
        rowData: [],
      });
    } else {
      gridApi.hideOverlay();
      gridApi.updateGridOptions({
        rowData,
        columnDefs,
      });
    }
  }, [gridApi, columnDefs, rowData, isLoading]);

  const itemName = _.find(items, { id: data?.state.version_history[0].data.item_id })?.name;
  const companyName = _.find(companies.data, { id: data?.state.version_history[0].data.company_id })?.name;

  const department = useMemo(() => {
    if (!data) return Department.PLANNER;
    const { current_approver, approvers } = data.search_attributes;
    if (!current_approver) {
      return Department.PCL;
    }

    const currentApproverIndex = _.findIndex(approvers, (approver) => approver.user.id == current_approver?.id);
    if (currentApproverIndex === 0) {
      return Department.PLANNER;
    } else if (approvers[currentApproverIndex + 1] === undefined) {
      return Department.PCL;
    } else {
      return Department.CT;
    }
  }, [data]);

  // getTooltipForApprove(
  //   data,
  //   checkUserForPermissions(user, [Permission.APP_DATA_PURCHASE_APPROVE], department).allowed,
  //   department,
  // );

  const approveTooltip = useMemo(() => {
    if (!data) return "";
    const { approvers, current_approver, approval_status } = data.search_attributes;
    const currentApproverIndex = _.findIndex(approvers, (approver) => approver.user.id == current_approver?.id);
    const { allowed } = checkUserForPermissions(user, [Permission.APP_DATA_PURCHASE_APPROVE], department);
    if (approval_status !== "AWAITING_APPROVAL") {
      return `Workflow is ${approval_status} and does not require further approval`;
    }
    if (!allowed) {
      return `Only the current assigned role ${department.toUpperCase()} can approve this workflow`;
    }

    if (approvers[currentApproverIndex + 1] === undefined) {
      return `Approve & change status to fully approved`;
    }
    return `Approve & assign to next approver`;
  }, [data]);

  const approveWithEditTooltip = useMemo(() => {
    if (!data) return "";
    const { approvers, current_approver, approval_status } = data.search_attributes;
    const currentApproverIndex = _.findIndex(approvers, (approver) => approver.user.id == current_approver?.id);

    const { allowed } = checkUserForPermissions(user, [Permission.APP_DATA_PURCHASE_EDIT], department);
    if (
      data?.search_attributes.approval_status === "APPROVED__SYNC_COMPLETE" &&
      checkUserForPermissions(user, [Permission.APP_DATA_PURCHASE_EDIT], department).allowed
    ) {
      return "Approve with your edits & assign to next approver";
    }
    if (approval_status !== "AWAITING_APPROVAL" && approval_status !== "APPROVED__SYNC_READY") {
      return `Workflow is ${approval_status} and cannot be edited`;
    }
    if (currentApproverIndex === -1 && approval_status === "APPROVED__SYNC_READY") {
      return "This workflow is already approved; editing will trigger re-approval if your edits are out of tolerance";
    }
    if (!allowed) {
      return `Only the current assigned role ${department.toUpperCase()} can edit this workflow`;
    }

    return `Approve with your edits & assign to next approver`;
  }, [data]);

  const cancelTooltip = useMemo(() => {
    if (!data) return "";
    const { allowed, reason } = checkUserForPermissions(user, [Permission.APP_DATA_PURCHASE_CANCEL]);
    const { approval_status } = data.search_attributes;
    if (approval_status !== "AWAITING_APPROVAL" && approval_status !== "APPROVED__SYNC_READY") {
      return `Workflow is ${approval_status} and cannot be canceled`;
    }
    if (allowed) {
      return "Cancel workflow, reverting the row to its original state. For correcting mistakes / cancelling requests";
    } else {
      console.log(reason);

      return reason;
    }
  }, []);

  return (
    <>
      <DialogHeader>
        <div className="flex justify-between items-center pr-5">
          <h3 className="text-base font-semibold">
            {companyName} - {itemName}
          </h3>
          <WorkflowModalStatusBar
            currentApprover={data?.search_attributes.current_approver}
            approversList={data?.search_attributes.approvers}
          />
          <Button
            data-testid="split-workflow"
            type="button"
            // @ts-expect-error TODO fix common types to prevent this
            variant="outline"
            className="gap-x-2 h-8"
            onClick={addNewRow}
          >
            <PlusIcon className="w-4 h-4 text-zinc-950" />
            Split
          </Button>
        </div>
      </DialogHeader>
      <div>
        <div className="flex justify-end"></div>
        <Card className="my-2.5 overflow-hidden">
          <CardContent className="p-0">
            <div className="ag-theme-quartz w-full h-[120px] pt-2">
              <AgGridReact
                gridId="workflow-current-version"
                suppressCellFocus
                suppressAutoSize
                reactiveCustomComponents
                stopEditingWhenCellsLoseFocus
                components={{
                  removeRowCell: RemoveRowCell,
                }}
                loadingOverlayComponent={() => <ReloadIcon className="h-6 w-6 animate-spin" />}
                noRowsOverlayComponent={() => <div>No data available</div>}
                onCellValueChanged={handleCheckSetDirty}
                onRowDataUpdated={handleCheckSetDirty}
                {...{ defaultColDef, onGridReady, getRowId }}
              />
            </div>
          </CardContent>
        </Card>

        <div className="flex flex-col items-end gap-2.5">
          <div className="flex gap-2.5 w-full justify-between flex-wrap">
            <div className="flex gap-2.5 items-center">
              <Tooltip tooltip={cancelTooltip}>
                <span tabIndex={0}>
                  <Button
                    data-testid="cancel-workflow"
                    disabled={
                      loadingAction !== null ||
                      (data?.search_attributes.approval_status !== "AWAITING_APPROVAL" &&
                        data?.search_attributes.approval_status !== "APPROVED__SYNC_READY") ||
                      !checkUserForPermissions(user, [Permission.APP_DATA_PURCHASE_CANCEL]).allowed
                    }
                    className={buttonVariants({ variant: "destructive" })}
                    onClick={() => handleModalCloseWithCallback(handleCancel)}
                  >
                    {loadingAction === "cancel" && <ReloadIcon className="mr-2 animate-spin" />}
                    Cancel Workflow
                  </Button>
                </span>
              </Tooltip>
            </div>

            <textarea
              value={memo}
              placeholder="Memo…"
              className=" w-1/2 max-w-72 p-1.5 border resize-none rounded-md text-sm"
              onChange={(e) => setMemo(e.target.value)}
            />

            <div className="flex gap-2.5 items-center">
              <Tooltip
                tooltip={
                  loadingAction !== null || !isDirty
                    ? "No changes detected. Edit a row to enable."
                    : approveWithEditTooltip
                }
              >
                <span tabIndex={0}>
                  <Button
                    data-testid="approve-with-edit"
                    // @ts-expect-error TODO fix common types to prevent this
                    variant="secondary"
                    disabled={
                      loadingAction !== null ||
                      !isDirty ||
                      (data?.search_attributes.approval_status !== "AWAITING_APPROVAL" &&
                        data?.search_attributes.approval_status !== "APPROVED__SYNC_READY" &&
                        !(
                          data?.search_attributes.approval_status === "APPROVED__SYNC_COMPLETE" &&
                          checkUserForPermissions(user, [Permission.APP_DATA_PURCHASE_EDIT], department).allowed
                        )) ||
                      !checkUserForPermissions(user, [Permission.APP_DATA_PURCHASE_EDIT], department).allowed
                    }
                    className={`${isDirty && "bg-blue-500 hover:bg-blue-600 text-white"}`}
                    onClick={() => handleModalCloseWithCallback(handleApproveWithEdit)}
                  >
                    {loadingAction === "edit" && <ReloadIcon className="mr-2 animate-spin" />}
                    Approve with edit
                  </Button>
                </span>
              </Tooltip>
              <Tooltip
                tooltip={
                  loadingAction !== null || isDirty
                    ? "Changes detected. Approving disallowed when you have made edits"
                    : approveTooltip
                }
              >
                <span tabIndex={0}>
                  <Button
                    data-testid="approve-workflow"
                    // @ts-expect-error TODO fix common types to prevent this
                    variant="secondary"
                    disabled={
                      loadingAction !== null ||
                      isDirty ||
                      data?.search_attributes.approval_status !== "AWAITING_APPROVAL" ||
                      !checkUserForPermissions(user, [Permission.APP_DATA_PURCHASE_APPROVE], department).allowed
                    }
                    className={`${!isDirty && "bg-blue-500 hover:bg-blue-600 text-white"}`}
                    onClick={() => handleModalCloseWithCallback(handleApprove)}
                  >
                    {loadingAction === "approve" && <ReloadIcon className="mr-2 animate-spin" />}
                    Approve
                  </Button>
                </span>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
