import { addDays, subDays } from "date-fns";
import {
  APPROVAL_STATUS_ID_TEXT_MAPPING,
  DAYS_CARGO_READY_TO_SHIP,
  Identity,
  ItemConfig,
  ItemVendorConfig,
  ShippingMethodEnum,
  StateTransitionFull,
  User,
} from "./types";
import { ItemWithConfig } from "./types";

export const getShippingMethodFromStateTransitionMetadata = (stateTransition: StateTransitionFull) => {
  //** Handles validation & default fallback of shipping methods */
  const smInput = stateTransition?.source_metadata?.shipping_method;
  if (typeof smInput === "string" && Object.values(ShippingMethodEnum).includes(smInput as ShippingMethodEnum)) {
    return smInput as ShippingMethodEnum;
  }
  if (smInput) {
    console.warn("Found shipping method that doesnt match the enum set: ", smInput);
  }
  return ShippingMethodEnum.Ocean;
};

export const getVendorApprovalStatus = (itemVendorConfig: ItemVendorConfig) => {
  //** Get vendor approval status & handle default fallback */
  if (!(itemVendorConfig.vendor_status_id in APPROVAL_STATUS_ID_TEXT_MAPPING)) {
    console.error(`Unrecognized vendor approval status: #${itemVendorConfig.vendor_status_id}`);
    return `Unrecognized Status: #${itemVendorConfig.vendor_status_id}`;
  }
  return APPROVAL_STATUS_ID_TEXT_MAPPING[itemVendorConfig.vendor_status_id];
};

export const getItemMinBalance = (item: ItemWithConfig, stateIds?: (number | string)[]) => {
  //** Sum min bal across all locations if stateIds is undefined, otherwise only if state id in list */
  let total = 0;
  let stateIdsStr: string[];
  if (stateIds) {
    stateIdsStr = stateIds.map((id) => id.toString());
  }
  Object.entries(item.config.location).forEach(([id, locConfig]) => {
    if (stateIdsStr) {
      if (stateIdsStr.includes(id)) {
        total += locConfig.item_inventory_quantity_min;
      }
    } else {
      // Total across all locations
      total += locConfig.item_inventory_quantity_min;
    }
  });

  return total;
};

const getFullItemNameString = (item: ItemWithConfig): string => {
  //** Not for display... */
  return `${item.source_metadata.item_name_prefix || ""}${item.name}${item.variant || ""}`;
};

export const filterItemsOnFullName = (items: ItemWithConfig[], filterQuery: string): ItemWithConfig[] => {
  //** Standard filter function to search on full name (including prefix and suffix) */

  return items.filter((item: ItemWithConfig) => {
    return getFullItemNameString(item).toLowerCase().includes(filterQuery.toLowerCase());
  });
};

export const compareFullItemNames = (a: ItemWithConfig, b: ItemWithConfig): number => {
  //** Comparator for standard lexiographic sorting for items */

  // Primary sort by name
  const nameComparison = a.name.localeCompare(b.name);
  if (nameComparison !== 0) return nameComparison;

  // Secondary sort by variant (descending order)
  if (a.variant && b.variant) {
    return b.variant.localeCompare(a.variant);
  }

  // Handle cases where variant might be undefined
  if (a.variant) return -1;
  if (b.variant) return 1;

  return 0;
};

export const getDisplayNameForIdentity = (identity: Identity | User) => {
  //** Get the given_name + family_name for a user, or fall back to id */
  if ("given_name" in identity && "family_name" in identity) {
    return `${identity.given_name} ${identity.family_name}`;
  } else {
    return identity.id;
  }
};

export const getWarningList = (
  vendor: ItemVendorConfig | undefined,
  itemConfig: ItemConfig | undefined,
  quantity: number,
): string[] => {
  if (!itemConfig || !vendor) return [];
  const warningList: string[] = [];

  if (quantity % itemConfig.item_lot_size !== 0) {
    warningList.unshift(`Quantity ${quantity} is not a multiple of item lot size ${itemConfig.item_lot_size}`);
  }
  if (vendor && vendor.minimum_order_quantity > quantity) {
    warningList.unshift(
      `Quantity ${quantity} is below ${vendor.state_name} min order qty of ${vendor.minimum_order_quantity}`,
    );
  }

  if (vendor && vendor.vendor_preferred_for_item === 0) {
    warningList.unshift(`Vendor ${vendor.state_name} is not preferred for this item`);
  }
  if (vendor && getVendorApprovalStatus(vendor) === "Not Approved") {
    warningList.unshift("Vendor is not approved for this item");
  }
  return warningList;
};

/** Standard function to calculate row dates given lead time */
export const calculateRowDates = (
  item: ItemWithConfig,
  vendorId: number,
  shippingMethod: ShippingMethodEnum,
  dateInputType: "atVendor"|"cargoReady" | "atShipment" | "atLocation",
  dateInput: Date,
): { atVendor: Date; atShipment: Date; atLocation: Date } => {
  const itemVendorConfig = item.config.vendor[vendorId];
  const vendorTimeDays = itemVendorConfig.vendor_time_days;
  let shipmentTimeDays;
  if (shippingMethod === ShippingMethodEnum.Ocean) {
    shipmentTimeDays = itemVendorConfig.shipment_time_days;
  } else if (shippingMethod === ShippingMethodEnum.Air) {
    shipmentTimeDays = item.config.company.shipment_time_days_air;
  } else if (shippingMethod === ShippingMethodEnum.FastVessel) {
    shipmentTimeDays = item.config.company.shipment_time_days_fastvessel;
  } else {
    throw new Error(`Unrecognized shipping method, ${shippingMethod}`);
  }

  if (shipmentTimeDays === undefined) {
    throw new Error(`Failed to find shipment time days for shipping method ${shippingMethod} `);
  }

  let atVendor;
  let atLocation;
  let atShipment;

  if (dateInputType === "atLocation") {
    atLocation = dateInput;
    atShipment = subDays(atLocation, shipmentTimeDays);
    atVendor = subDays(atShipment, vendorTimeDays);
  } else if (dateInputType === "cargoReady") {
    atShipment = addDays(dateInput, DAYS_CARGO_READY_TO_SHIP);
    atLocation = addDays(atShipment, shipmentTimeDays);
    atVendor = subDays(atShipment, vendorTimeDays);
  } else if (dateInputType === "atShipment") {
    atShipment = dateInput;
    atLocation = addDays(atShipment, shipmentTimeDays);
    atVendor = subDays(atShipment, vendorTimeDays);
  } else if (dateInputType == "atVendor") {
    atVendor = dateInput;
    atShipment = addDays(atVendor, vendorTimeDays);
    atLocation = addDays(atShipment, shipmentTimeDays);
  } else {
    throw new Error("Unrecognized date input type");
  }

  return { atVendor, atShipment, atLocation };
};
